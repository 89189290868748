.button__1Gema {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-radius: 100px;
  white-space: nowrap;
}

@media only screen and (min-width: 450px) {
  .button__1Gema {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .button__1Gema {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .button__1Gema {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .button__1Gema {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px) {
  .button__1Gema {
    padding: 0 calc(6.4vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .button__1Gema {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .button__1Gema {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .button__1Gema {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.button__1Gema.fullWidth__2TCp5 {
  width: 100%;
}

.button__1Gema.xs__IzHoF {
  height: 32px;
}

@media only screen and (min-width: 1200px) {
  .button__1Gema.xs__IzHoF {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .button__1Gema.xs__IzHoF {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .button__1Gema.xs__IzHoF {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.button__1Gema.s__2LUL9 {
  height: 36px;
}

@media only screen and (min-width: 1200px) {
  .button__1Gema.s__2LUL9 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .button__1Gema.s__2LUL9 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .button__1Gema.s__2LUL9 {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.button__1Gema.ms__nb610 {
  height: 40px;
}

.button__1Gema.m__3XPSU {
  height: 44px;
}

.button__1Gema.l__1hQNn {
  height: 56px;
}

a.button__1Gema {
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).primary-inverted__jjTUx {
  background-color: #fff;
  color: #4c6072;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).primary-inverted__jjTUx .loader__1rS98 path {
  fill: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).primary-inverted__jjTUx:hover {
  opacity: .7;
}

.button__1Gema:not(.disabled__l-Yz0).primary__J3Cob {
  background-color: #4c6072;
  color: #fff;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).primary__J3Cob .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).primary__J3Cob:hover {
  opacity: .7;
}

.button__1Gema:not(.disabled__l-Yz0).notify__lTTL8 {
  color: #fff;
  background: #df5475;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).notify__lTTL8 .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).notify__lTTL8:hover {
  color: #fff;
  background: #c0506b;
}

.button__1Gema:not(.disabled__l-Yz0).success__3PNXQ {
  color: #fff;
  background: #97b7b6;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).success__3PNXQ .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).success__3PNXQ:hover {
  color: #fff;
  background: #3c676e;
}

.button__1Gema:not(.disabled__l-Yz0).normal__1RRkG {
  border: 1px solid #4c6072;
  color: #4c6072;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).normal__1RRkG .loader__1rS98 path {
  fill: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).normal__1RRkG:hover {
  background: #f3f5f5;
  border: 1px solid #f3f5f5;
}

.button__1Gema:not(.disabled__l-Yz0).normal-inverted__lyHlq {
  border: 1px solid rgba(255,255,255,.5);
  color: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).normal-inverted__lyHlq .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).normal-inverted__lyHlq:hover {
  background: #fff;
  color: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).link__UT5ej {
  border: none;
  color: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).link__UT5ej .loader__1rS98 path {
  fill: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).link__UT5ej:hover .button-inner__3gD1I {
  border-bottom: 1px solid #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).underline__mhyZA {
  font-weight: normal;
  color: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).underline__mhyZA .loader__1rS98 path {
  fill: #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).underline__mhyZA .button-inner__3gD1I {
  border-bottom: 1px solid #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).underline__mhyZA:hover .button-inner__3gD1I {
  border-bottom: 1px solid #4c6072;
}

.button__1Gema:not(.disabled__l-Yz0).link-inverted__19l_u {
  border: none;
  color: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).link-inverted__19l_u .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).link-inverted__19l_u:hover .button-inner__3gD1I {
  border-bottom: 1px solid #fff;
}

.button__1Gema:not(.disabled__l-Yz0).multicreator__41qtu {
  color: #fff;
  background-color: #99293d;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).multicreator__41qtu .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).multicreator__41qtu:hover {
  opacity: .7;
}

.button__1Gema:not(.disabled__l-Yz0).off__2qjeX {
  background-color: #f3f5f5;
  color: #d3d4d8;
  text-decoration: none;
}

.button__1Gema:not(.disabled__l-Yz0).off__2qjeX .loader__1rS98 path {
  fill: #fff;
}

.button__1Gema:not(.disabled__l-Yz0).off__2qjeX:hover {
  opacity: .7;
}

.button__1Gema.disabled__l-Yz0 {
  cursor: default;
}

.button__1Gema.disabled__l-Yz0.normal__1RRkG, .button__1Gema.disabled__l-Yz0.notify__lTTL8, .button__1Gema.disabled__l-Yz0.success__3PNXQ, .button__1Gema.disabled__l-Yz0.multicreator__41qtu, .button__1Gema.disabled__l-Yz0.primary__J3Cob {
  background: #f3f5f5;
  color: #d3d4d8;
}

.button__1Gema.disabled__l-Yz0.primary-inverted__jjTUx {
  border: 1px solid #dadbdd;
  color: #d3d4d8;
  background: none;
}

.button__1Gema.disabled__l-Yz0.link__UT5ej, .button__1Gema.disabled__l-Yz0.link-inverted__19l_u, .button__1Gema.disabled__l-Yz0.normal-inverted__lyHlq, .button__1Gema.disabled__l-Yz0.underline__mhyZA {
  color: #e8ecef;
}

@media only screen and (min-width: 450px) {
  .loader__1rS98 {
    width: 20px;
    height: auto;
  }
}

@media only screen and (max-width: 449px) {
  .loader__1rS98 {
    width: calc(5.3333333333vw);
    height: auto;
  }
}

