.loader-wrapper__JAW_m {
  margin: auto;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
}

.loader-wrapper__JAW_m.exiting__2fHAx, .loader-wrapper__JAW_m.exited__39_EM {
  opacity: 0;
}

.loader-wrapper__JAW_m.entering__1ti5z, .loader-wrapper__JAW_m.entered__2xR3X {
  opacity: 1;
}

.loader__3kQ4o {
  width: 50px;
  height: auto;
}

.loader__3kQ4o path {
  fill: #fff;
}

