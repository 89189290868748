@media only screen and (min-width: 450px) {
  .logo__1RuBT {
    width: 80px;
  }
}

@media only screen and (max-width: 449px) {
  .logo__1RuBT {
    width: calc(14.9333333333vw);
    height: auto;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(14.9333333333vw);
            flex: 0 0 calc(14.9333333333vw);
  }
}

.desktop-navigation__xxANx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 0 12px 32px;
}

.user-info-wrapper__ZQP9j {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.sp-navigation-header__mfwH0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: calc(3.2vw);
}

.hamburger-button__QWNW5 {
  z-index: 5002;
}

.menu__3emqj {
  position: fixed;
  z-index: 5001;
  width: 60vw;
  height: 100vh;
  background: #fff;
}

@media only screen and (min-width: 450px) {
  .menu__3emqj {
    padding: 56px 32px;
  }
}

@media only screen and (max-width: 449px) {
  .menu__3emqj {
    padding: calc(14.9333333333vw) 0 calc(42.1333333333vw) 0;
    overflow-y: scroll;
  }
}

