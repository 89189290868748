.form-input-wrapper__1FYwL {
  display: inline-block;
}

.form-input-wrapper__1FYwL .error__2Sxkg {
  color: #df5475;
  margin-top: 8px;
}

@media only screen and (min-width: 1200px) {
  .form-input-wrapper__1FYwL .error__2Sxkg {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .form-input-wrapper__1FYwL .error__2Sxkg {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .form-input-wrapper__1FYwL .error__2Sxkg {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.form-input__3V28H {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 12px;
  padding: 17px 12px;
  width: 100%;
  background: #fbfbfc;
  border-radius: 8px;
  border: 0px;
}

input.form-input__3V28H {
  height: 40px;
}

.form-input__3V28H+.form-input__3V28H {
  margin-left: 7px;
}

.account-page-form-input__2tp8P {
  line-height: 20px;
  padding: 14px 18px 14px 24px;
}

input.account-page-form-input__2tp8P {
  height: 48px;
}

