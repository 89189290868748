@media only screen and (min-width: 450px) {
  .container__bIQ3A {
    margin-top: -16px;
  }
}

@media only screen and (max-width: 449px) {
  .container__bIQ3A {
    margin-top: calc(-4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .container__bIQ3A.s__1Avwa {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: -16px;
    margin-top: -16px;
  }
}

@media only screen and (min-width: 450px) {
  .option__7Jyq_ {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .option__7Jyq_ {
    margin-top: calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .option__7Jyq_.s__1Avwa {
    margin-right: 16px;
    margin-top: 16px;
    width: 246px;
  }
}

