.question-card__1d4we {
  background: #fff;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  -webkit-box-shadow: 0 0 57px rgba(0,0,0,.06);
          box-shadow: 0 0 57px rgba(0,0,0,.06);
  border-radius: 6px;
}

@media only screen and (min-width: 450px) {
  .question-card__1d4we {
    width: 628px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (min-width: 450px) {
  .question-card__1d4we+.question-card__1d4we {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .question-card__1d4we+.question-card__1d4we {
    margin-top: calc(6.4vw);
  }
}

.question-header__pB5sd {
  position: relative;
  text-align: center;
}

@media only screen and (min-width: 450px) {
  .question-header__pB5sd {
    padding: 40px 60px 0;
  }
}

@media only screen and (max-width: 449px) {
  .question-header__pB5sd {
    padding: calc(6.4vw) calc(6.4vw) 0;
  }
}

.question-header-one-question__1TMDP {
  position: relative;
  text-align: center;
  background-color: #f3f5f5;
  border-radius: 6px 6px 0px 0px;
}

@media only screen and (min-width: 450px) {
  .question-header-one-question__1TMDP {
    padding: 24px 20px 13px;
  }
}

@media only screen and (max-width: 449px) {
  .question-header-one-question__1TMDP {
    padding: calc(5.3333333333vw) calc(6.4vw) calc(3.4666666667vw);
  }
}

.sentence__1R4vm {
  color: #1f2c32;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .sentence__1R4vm {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .sentence__1R4vm {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .sentence__1R4vm {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .sentence__1R4vm {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 449px) {
  .sentence__1R4vm {
    margin-top: calc(4.2666666667vw);
  }
}

.sentence-one-question__2lXv0 {
  color: #1f2c32;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .sentence-one-question__2lXv0 {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .sentence-one-question__2lXv0 {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .sentence-one-question__2lXv0 {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .sentence-one-question__2lXv0 {
    font-size: 14px;
    margin-top: 26px;
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 449px) {
  .sentence-one-question__2lXv0 {
    margin-top: calc(5.8666666667vw);
    margin-bottom: calc(5.8666666667vw);
  }
}

.sub-sentence__FaboF {
  color: #6b9aaa;
}

@media only screen and (min-width: 1200px) {
  .sub-sentence__FaboF {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .sub-sentence__FaboF {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-sentence__FaboF {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .sub-sentence__FaboF {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-sentence__FaboF {
    margin-top: calc(4.2666666667vw);
  }
}

.sub-sentence__FaboF a {
  color: #6b9aaa;
}

.sub-sentence-one-question__2xLwG {
  color: #1f2c32;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .sub-sentence-one-question__2xLwG {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .sub-sentence-one-question__2xLwG {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-sentence-one-question__2xLwG {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .sub-sentence-one-question__2xLwG {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-sentence-one-question__2xLwG {
    margin-bottom: calc(6.4vw);
  }
}

.sub-sentence-one-question__2xLwG a {
  color: #6b9aaa;
}

@media only screen and (min-width: 450px) {
  .error__2IKFL {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 449px) {
  .error__2IKFL {
    margin-top: calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .question-body__XZMHv {
    padding: 32px 60px 40px;
  }
}

@media only screen and (max-width: 449px) {
  .question-body__XZMHv {
    padding: calc(8.5333333333vw) calc(6.4vw);
  }
}

.number-indicator__Mw4p0 {
  position: absolute;
}

@media only screen and (min-width: 450px) {
  .number-indicator__Mw4p0 {
    top: 12px;
    right: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .number-indicator__Mw4p0 {
    top: calc(2.1333333333vw);
    right: calc(6.4vw);
  }
}

.progress-bar__1aO3w {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

@media only screen and (min-width: 450px) {
  .progress-bar__1aO3w {
    padding: 0px 135px;
  }
}

@media only screen and (max-width: 449px) {
  .progress-bar__1aO3w {
    margin-top: calc(3.7333333333vw);
  }
}

.bar-wrapper__2zIhE {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background: #fbfbfc;
  border-radius: 94.1232px;
}

@media only screen and (min-width: 1200px) {
  .bar-wrapper__2zIhE {
    margin-right: 24px;
    height: 6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .bar-wrapper__2zIhE {
    margin-right: 11px;
    height: 6px;
    width: 90%;
  }
}

@media only screen and (max-width: 449px) {
  .bar-wrapper__2zIhE {
    margin-right: calc(2.9333333333vw);
    height: calc(1.6vw);
    width: calc(25.6vw);
  }
}

.bar__1Gw96 {
  background: #4c6072;
  border-radius: 94.1232px;
  height: 100%;
}

.text__2LtfB {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
  color: #4c6072;
}

@media only screen and (min-width: 1200px) {
  .text__2LtfB {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .text__2LtfB {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .text__2LtfB {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .modal__29d0A {
    margin: 72px auto;
    width: 487px;
  }
}

@media only screen and (max-width: 449px) {
  .modal__29d0A {
    margin: calc(19.2vw) auto;
  }
}

