.check-box-input-wrapper__2usOQ {
  display: inline-block;
}

.check-box-input-wrapper__2usOQ .error__1FbJv {
  color: #df5475;
  margin-top: 8px;
}

@media only screen and (min-width: 1200px) {
  .check-box-input-wrapper__2usOQ .error__1FbJv {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .check-box-input-wrapper__2usOQ .error__1FbJv {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-input-wrapper__2usOQ .error__1FbJv {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

