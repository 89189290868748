.indicator__PMMm- {
  font-family: europa,sans-serif;
  color: #d3d4d8;
}

@media only screen and (min-width: 1200px) {
  .question-number__f3NHf {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .question-number__f3NHf {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .question-number__f3NHf {
    font-size: calc(5.3333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (min-width: 1200px) {
  .separator__1kUx_ {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .separator__1kUx_ {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .separator__1kUx_ {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .separator__1kUx_ {
    margin: 0 4px;
  }
}

@media only screen and (max-width: 449px) {
  .separator__1kUx_ {
    margin: 0 calc(1.0666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .total-questions__3bTuB {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .total-questions__3bTuB {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .total-questions__3bTuB {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

