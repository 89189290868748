@media only screen and (min-width: 450px) {
  .section__9u3_Q {
    margin-top: 36px;
  }
}

@media only screen and (max-width: 449px) {
  .section__9u3_Q {
    margin-top: calc(9.6vw);
  }
}

.title__2pQVp {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
  border-left: 3px solid #4c6072;
  color: #4c6072;
}

@media only screen and (min-width: 1200px) {
  .title__2pQVp {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .title__2pQVp {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .title__2pQVp {
    font-size: calc(4.2666666667vw);
    line-height: calc(8.5333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .title__2pQVp {
    padding-left: 12px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .title__2pQVp {
    padding-left: calc(3.2vw);
    line-height: calc(6.4vw);
  }
}

@media only screen and (min-width: 450px) {
  .body__2GT9i {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .body__2GT9i {
    margin-top: calc(6.4vw);
  }
}

@media only screen and (min-width: 450px) {
  .withdrawal-label-wrapper__1ijDj {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 449px) {
  .withdrawal-label-wrapper__1ijDj {
    margin-bottom: calc(5.3333333333vw);
  }
}

