.sign-out-text__30Wb3 {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: 2px;
  color: #191921;
}

@media only screen and (max-width: 769px) {
  .sign-out-text__30Wb3 {
    font-size: 13px;
    line-height: 2;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 769px) {
  .sign-out-text__30Wb3 {
    padding-top: calc(5vw);
  }
}

