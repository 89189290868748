@media only screen and (min-width: 450px) {
  .section__1aPFS {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .section__1aPFS {
    margin-top: calc(6.4vw);
  }
}

.label__2oO5e {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .label__2oO5e {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .label__2oO5e {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__2oO5e {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .body__vGYsW {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .body__vGYsW {
    margin-top: calc(2.1333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .errors__3uGQY {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .errors__3uGQY {
    margin-top: calc(2.1333333333vw);
  }
}

.error__1kZiE {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #df5475;
}

@media only screen and (min-width: 1200px) {
  .error__1kZiE {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .error__1kZiE {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .error__1kZiE {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.button-wrapper__3ent5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .button-wrapper__3ent5 {
    margin: 56px 0;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .button-wrapper__3ent5 {
    margin: 56px 0;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__3ent5 {
    margin: calc(10.6666666667vw) 0;
  }
}

@media only screen and (min-width: 450px) {
  .submit-button__1dMIm {
    width: 240px;
  }
}

@media only screen and (max-width: 449px) {
  .submit-button__1dMIm {
    width: 100%;
  }
}

