* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.form-label__3XolC {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.71px;
  margin-top: 32px;
  margin-bottom: 20px;
}

.email__Pa3in {
  width: 100%;
}

.form-submit-button__3x6ct {
  margin-bottom: 48px;
}

@media only screen and (max-width: 449px) {
  .form-submit-button__3x6ct {
    margin-top: calc(12.8vw);
  }
}

@media only screen and (min-width: 450px) {
  .form-submit-button__3x6ct {
    margin-top: 56px;
  }
}

.button__1C7IF {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  width: 196px;
  background: #4c6072;
  color: #fff;
  border: 1px solid #4c6072;
  border-radius: 400px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 449px) {
  .button__1C7IF {
    width: 100%;
  }
}

.button__1C7IF:hover {
  opacity: .7;
}

.button__1C7IF.disabled__2M5fg {
  cursor: default;
  opacity: .5;
}

@media only screen and (min-width: 1200px) {
  .note__1teVY {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .note__1teVY {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .note__1teVY {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .loader__3xJv5 {
    width: 20px;
    height: 48px;
  }
}

@media only screen and (max-width: 449px) {
  .loader__3xJv5 {
    width: calc(5.3333333333vw);
    height: 48px;
  }
}

@media only screen and (min-width: 450px) {
  .receive-notification-emails__1NKwp {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .receive-notification-emails__1NKwp {
    margin-top: calc(6.4vw);
  }
}

