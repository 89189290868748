.youtube__iyqpQ {
  width: 100%;
  aspect-ratio: 16/9;
  margin-top: 30px;
}

.youtube__iyqpQ iframe {
  width: 100%;
  height: 100%;
}

