.check-box__1uq2G {
  background: #fff;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 450px) {
  .check-box__1uq2G {
    border: 2px solid #d3d4d8;
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box__1uq2G {
    border: calc(0.5333333333vw) solid #d3d4d8;
    width: calc(4.2666666667vw);
    height: calc(4.2666666667vw);
  }
}

.badge__2EcLd {
  background: #df5475;
  color: #fff;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 450px) {
  .badge__2EcLd {
    margin-left: 12px;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 1.6px;
    padding: 4px 8px;
  }
}

@media only screen and (max-width: 449px) {
  .badge__2EcLd {
    margin-left: calc(3.2vw);
    font-size: calc(2.4vw);
    line-height: calc(3.2vw);
    letter-spacing: calc(0.4266666667vw);
    padding: calc(1.0666666667vw) calc(2.1333333333vw);
  }
}

.checked-icon__1a3JQ {
  color: #d6a0a0;
  width: 16px;
  height: auto;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.check-box-card__2AfAF {
  background: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  border: 2px solid #dadbdd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  font-family: europa,游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
}

.check-box-card__2AfAF.checked__1Wzos {
  background: #f3f5f5;
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .check-box-card__2AfAF {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .check-box-card__2AfAF {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .check-box-card__2AfAF {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .check-box-card__2AfAF {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .check-box-card__2AfAF {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .check-box-card__2AfAF {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .check-box-card__2AfAF {
    min-width: 160px;
    padding: 20px;
  }

  .check-box-card__2AfAF.l__3YOkE {
    width: 100%;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card__2AfAF {
    padding: calc(4.2666666667vw) calc(3.2vw) calc(4.2666666667vw) calc(5.3333333333vw);
  }

  .check-box-card__2AfAF.s__3WB-f {
    width: calc(41.0666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .check-box-card__2AfAF .chk__2VNhv {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card__2AfAF .chk__2VNhv {
    margin-right: calc(5.3333333333vw);
  }
}

.body__1sVUj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.label__3V38j {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.sub-label__11_uh {
  color: #afb0b5;
  font-weight: normal;
  text-align: right;
}

@media only screen and (min-width: 1200px) {
  .sub-label__11_uh {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .sub-label__11_uh {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-label__11_uh {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.description__2yu_a {
  color: #afb0b5;
  font-weight: normal;
  margin-top: 5px;
}

@media only screen and (min-width: 1200px) {
  .description__2yu_a {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .description__2yu_a {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .description__2yu_a {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.check-box-card-fa__272qD {
  background: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  border: 2px solid #dadbdd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-family: europa,游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
}

.check-box-card-fa__272qD.checked__1Wzos {
  background: #f3f5f5;
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .check-box-card-fa__272qD {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .check-box-card-fa__272qD {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .check-box-card-fa__272qD {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .check-box-card-fa__272qD {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .check-box-card-fa__272qD {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .check-box-card-fa__272qD {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .check-box-card-fa__272qD {
    min-width: 160px;
    padding: 20px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card-fa__272qD {
    padding: calc(4.2666666667vw) calc(3.2vw) calc(4.2666666667vw) calc(5.3333333333vw);
  }

  .check-box-card-fa__272qD.s__3WB-f {
    width: calc(41.0666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .check-box-card-fa__272qD .chk__2VNhv {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card-fa__272qD .chk__2VNhv {
    margin-right: calc(5.3333333333vw);
  }
}

.fa-top__1FGsF {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fa-input__53tgN {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

@media only screen and (max-width: 449px) {
  .fa-input__53tgN {
    min-height: calc(21.3333333333vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .fa-input__53tgN {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .fa-input__53tgN {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .fa-input__53tgN {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .fa-input__53tgN::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__53tgN::-ms-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__53tgN::placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .fa-input__53tgN::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__53tgN::-ms-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__53tgN::placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .fa-input__53tgN::-webkit-input-placeholder {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .fa-input__53tgN::-ms-input-placeholder {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .fa-input__53tgN::placeholder {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.fa-bottom__3No_X {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 450px) {
  .fa-bottom__3No_X {
    margin-left: 36px;
    margin-top: 8px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 449px) {
  .fa-bottom__3No_X {
    margin-top: calc(2.1333333333vw);
    margin-right: calc(3.2vw);
  }
}

