.payment-info__1Ra36 {
  margin-bottom: 10px;
  background: #f6f7f7;
}

@media only screen and (min-width: 1200px) {
  .payment-info__1Ra36 {
    padding: 40px 40px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .payment-info__1Ra36 {
    padding: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .payment-info__1Ra36 {
    padding: min(6.4vw,24px);
  }
}

.payment-info__1Ra36 .heading__3OUU7 {
  display: block;
  margin-bottom: min(5.3333333333vw,20px);
}

.payment-info__1Ra36 .card-number__27In2 {
  margin-bottom: min(6.4vw,24px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.payment-info__1Ra36 .card-number__27In2 .brand-icon__ReWqK {
  width: 35px;
}

.payment-info__1Ra36 .card-number__27In2 .card-number-text__37R2d {
  padding-left: min(6.4vw,24px);
}

