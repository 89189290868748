.pane___VjGe {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 450px) {
  .button-wrapper__dTBK5 {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__dTBK5 {
    margin-top: calc(8.5333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .button__mU2YN {
    width: 240px;
  }
}

.header__2LbE3 {
  text-align: center;
}

@media only screen and (min-width: 450px) {
  .header__2LbE3 {
    margin: 40px 0 25px;
  }
}

@media only screen and (max-width: 449px) {
  .header__2LbE3 {
    margin: calc(10.6666666667vw) 0 calc(6.6666666667vw);
  }
}

.title__2nID8 {
  color: #1f2c32;
  font-weight: bold;
  font-size: 36px;
  line-height: 20px;
  letter-spacing: 4.6px;
}

@media only screen and (min-width: 450px) {
  .title__2nID8 {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .title__2nID8 {
    margin-bottom: calc(8.5333333333vw);
  }
}

.text__2DLNT {
  color: #1f2c32;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .text__2DLNT {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .text__2DLNT {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .text__2DLNT {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .text__2DLNT {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 449px) {
  .text__2DLNT {
    margin-bottom: calc(1.3333333333vw);
  }
}

@media only screen and (min-width: 1200px) {
  .name__FPUQo {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .name__FPUQo {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .name__FPUQo {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.body__3vhUX {
  background-image: url(/packs/attendance_bg-063c7a43ce22755fbe6b57044bf874e7.png);
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 450px) {
  .body__3vhUX {
    padding: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .body__3vhUX {
    padding: calc(6.4vw);
  }
}

.reservation_count__3FJFR {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
  color: #1f2c32;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .reservation_count__3FJFR {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .reservation_count__3FJFR {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .reservation_count__3FJFR {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .reservation_count__3FJFR {
    padding: 26px 0 14px;
  }
}

@media only screen and (max-width: 449px) {
  .reservation_count__3FJFR {
    padding: calc(6.9333333333vw) 0 calc(3.7333333333vw);
  }
}

.no__LiNAT {
  font-family: europa,sans-serif;
}

@media only screen and (min-width: 450px) {
  .no__LiNAT {
    font-size: 20px;
    margin: 0 4px;
  }
}

@media only screen and (max-width: 449px) {
  .no__LiNAT {
    font-size: calc(5.3333333333vw);
  }
}

