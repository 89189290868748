.input__1p1i9 {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media only screen and (min-width: 450px) {
  .form__268QR {
    max-width: 607px;
  }
}

@media only screen and (min-width: 450px) {
  .form-row__EcQ9E {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .form-row__EcQ9E {
    margin-top: calc(4.2666666667vw);
  }
}

.form-row-body__g09jN {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.form-row-body__g09jN div {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media only screen and (min-width: 450px) {
  .form-row-body__g09jN .input-wrapper__3EEce:first-child {
    margin-right: 13px;
  }
}

@media only screen and (max-width: 449px) {
  .form-row-body__g09jN .input-wrapper__3EEce:first-child {
    margin-right: calc(3.4666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .label__1Cbh7 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .label__1Cbh7 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__1Cbh7 {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .label__1Cbh7 {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 449px) {
  .label__1Cbh7 {
    margin-bottom: calc(1.0666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .button__1TXQA {
    max-width: 160px;
  }
}

@media only screen and (max-width: 449px) {
  .button__1TXQA {
    max-width: calc(42.6666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .input-wrapper__3EEce.security__2Rlsv {
    max-width: 160px;
  }
}

@media only screen and (max-width: 449px) {
  .input-wrapper__3EEce.security__2Rlsv {
    max-width: calc(42.6666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .input-wrapper__3EEce {
    width: calc(50% - 6.5px);
  }
}

@media only screen and (max-width: 449px) {
  .input-wrapper__3EEce {
    width: calc(50% - calc(1.7333333333vw));
  }
}

.input__1p1i9 {
  background: #fbfbfc;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  border: none;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .input__1p1i9 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .input__1p1i9 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .input__1p1i9 {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.input__1p1i9::-webkit-input-placeholder {
  color: #e8ecef;
}

.input__1p1i9::-ms-input-placeholder {
  color: #e8ecef;
}

.input__1p1i9::placeholder {
  color: #e8ecef;
}

@media only screen and (min-width: 450px) {
  .input__1p1i9 {
    border-radius: 8px;
    padding: 17px 12px;
  }
}

@media only screen and (max-width: 449px) {
  .input__1p1i9 {
    padding: calc(4.5333333333vw) calc(3.2vw);
  }
}

.input__1p1i9.number__11wxq {
  width: 100%;
}

.card-summary__1k8Dp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (min-width: 450px) {
  .card-summary__1k8Dp {
    margin-top: 60px;
    width: 327px;
  }
}

@media only screen and (max-width: 449px) {
  .card-summary__1k8Dp {
    margin-top: calc(8.5333333333vw);
  }
}

.summary-main__2sY8i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 450px) {
  .brand-icon__3E8nz {
    width: 35px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 449px) {
  .brand-icon__3E8nz {
    width: calc(9.3333333333vw);
    margin-right: calc(4vw);
  }
}

.number-mask__205kc {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .number-mask__205kc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .number-mask__205kc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .number-mask__205kc {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.payjs-outer__1PxPl {
  height: 48px;
  border-radius: 8px;
  padding: 16px;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  background: #f3f5f5;
  border: 1px solid rgba(0,0,0,0);
  outline: none;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .payjs-outer__1PxPl {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .payjs-outer__1PxPl {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .payjs-outer__1PxPl {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.payjs-outer__1PxPl::-webkit-input-placeholder {
  color: #afb0b5;
}

.payjs-outer__1PxPl::-ms-input-placeholder {
  color: #afb0b5;
}

.payjs-outer__1PxPl::placeholder {
  color: #afb0b5;
}

@media only screen and (min-width: 1200px) {
  .payjs-outer__1PxPl::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .payjs-outer__1PxPl::-ms-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .payjs-outer__1PxPl::placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .payjs-outer__1PxPl::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .payjs-outer__1PxPl::-ms-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .payjs-outer__1PxPl::placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .payjs-outer__1PxPl::-webkit-input-placeholder {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .payjs-outer__1PxPl::-ms-input-placeholder {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .payjs-outer__1PxPl::placeholder {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.form-error__-pUpz {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #df5475;
}

@media only screen and (min-width: 1200px) {
  .form-error__-pUpz {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .form-error__-pUpz {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .form-error__-pUpz {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .form-error__-pUpz {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .form-error__-pUpz {
    margin-top: calc(4.2666666667vw);
  }
}

