.placeholder__2SOLW {
  margin-bottom: min(6.4vw,24px);
}

.button__2hoKH {
  font-size: 10px;
  font-weight: bold;
  line-height: 30px;
  width: 197px;
  color: #4c6072;
  border: 1px solid #4c6072;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 12px rgba(0,0,0,.03);
          box-shadow: 0px 1px 12px rgba(0,0,0,.03);
  border-radius: 112px;
  text-align: center;
  cursor: pointer;
}

.button__2hoKH:hover {
  opacity: .7;
}

