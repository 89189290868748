.input__2J5YR {
  background: #fbfbfc;
  border: none;
  width: 100%;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .input__2J5YR {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .input__2J5YR {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .input__2J5YR {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.input__2J5YR::-webkit-input-placeholder {
  color: #e8ecef;
}

.input__2J5YR::-ms-input-placeholder {
  color: #e8ecef;
}

.input__2J5YR::placeholder {
  color: #e8ecef;
}

@media only screen and (min-width: 450px) {
  .input__2J5YR {
    border-radius: 4px;
    padding: 17px 10px;
  }
}

@media only screen and (max-width: 449px) {
  .input__2J5YR {
    border-radius: calc(1.0666666667vw);
    padding: calc(4.5333333333vw) calc(2.6666666667vw);
  }
}

