.label__2VVyc {
  display: inline-block;
  font-weight: bold;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .label__2VVyc {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .label__2VVyc {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__2VVyc {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .label__2VVyc {
    padding: 4px 8px;
  }
}

@media only screen and (max-width: 449px) {
  .label__2VVyc {
    padding: 4px 8px;
  }
}

.label__2VVyc.danger__3dwK0 {
  color: #df5475;
  border: 1px solid #df5475;
}

.label__2VVyc.secondary__171j7 {
  color: #afb0b5;
  border: 1px solid #afb0b5;
}

