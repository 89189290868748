.logo__YkkSh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

@media only screen and (max-width: 769px) {
  .logo__YkkSh {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.logo__YkkSh.dark__2Q-Ib {
  color: #6d6d6d;
}

.logo__YkkSh.white__1F90u {
  color: #fff;
}

.header-logo__2dW65 * {
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.header-logo__2dW65.dark__2Q-Ib * {
  color: #1f2d32;
  fill: #1f2d32;
}

.header-logo__2dW65.white__1F90u * {
  color: #fff;
  fill: #fff;
}

@media only screen and (min-width: 450px) {
  .header-logo__2dW65 {
    width: auto;
    height: 50px;
  }
}

@media only screen and (max-width: 450px) {
  .header-logo__2dW65 {
    width: auto;
    min-width: 26px;
    height: calc(9.0625vw);
  }
}

.text__2ThK8 {
  font-family: Helvetica Neue;
  font-size: 9px;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: 2px;
  color: #6d6d6d;
  padding-right: 2px;
}

@media only screen and (max-width: 769px) {
  .text__2ThK8 {
    font-size: 9px;
    line-height: 1.6;
    letter-spacing: 2px;
  }
}

.header_sub_title__3o0uT {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  margin: auto 0;
}

@media only screen and (max-width: 769px) {
  .header_sub_title__3o0uT {
    display: none;
  }
}

