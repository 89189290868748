@media only screen and (min-width: 1200px) {
  .mob-br__3zD9s {
    display: none;
  }
}

@media only screen and (min-width: 450px) {
  .sp-br__2QwAd {
    display: none;
  }
}

@media only screen and (max-width: 449px) {
  .non-sp-br__3Z2xB {
    display: none;
  }
}

