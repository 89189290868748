@media only screen and (min-width: 450px) {
  .container__NJhF2 {
    margin-top: -16px;
  }
}

@media only screen and (max-width: 449px) {
  .container__NJhF2 {
    margin-top: calc(-4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .container__NJhF2.s__hAVur {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: -16px;
    margin-top: -16px;
  }
}

@media only screen and (min-width: 450px) {
  .option__1TQ5H {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .option__1TQ5H {
    margin-top: calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .option__1TQ5H.s__hAVur {
    margin-right: 16px;
    margin-top: 16px;
    width: 246px;
  }
}

