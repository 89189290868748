.cancellation-policy__29K7B {
  background: #f6f7f7;
  color: #4c6072;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .cancellation-policy__29K7B {
    margin: 0 auto 32px;
    padding: 40px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .cancellation-policy__29K7B {
    padding: 40px;
    margin: 0 auto 32px;
  }
}

@media only screen and (max-width: 449px) {
  .cancellation-policy__29K7B {
    margin: 0 auto min(8.5333333333vw,32px);
    padding: min(8.5333333333vw,32px) min(6.4vw,24px);
  }
}

.header__2rUwJ {
  text-align: center;
}

@media only screen and (min-width: 450px) {
  .header__2rUwJ {
    font-weight: bold;
  }
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .header__2rUwJ {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .header__2rUwJ {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .header__2rUwJ {
    font-size: calc(4.2666666667vw);
    line-height: calc(6.4vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (max-width: 449px) {
  .header__2rUwJ {
    font-weight: bold;
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .header__2rUwJ {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .header__2rUwJ {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .header__2rUwJ {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.header__2rUwJ h1 {
  color: #4c6072;
}

.body__1ipnM {
  text-align: left;
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 1em;
}

@media only screen and (min-width: 1200px) {
  .body__1ipnM {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .body__1ipnM {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .body__1ipnM {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .body__1ipnM {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .body__1ipnM {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 450px) {
  .body__1ipnM p {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .body__1ipnM p {
    margin-top: calc(4.2666666667vw);
  }
}

.body__1ipnM p.supplement__2Mvad {
  font-size: 9px;
}

.body__1ipnM .link__21hLZ, .body__1ipnM .link__21hLZ:active, .body__1ipnM .link__21hLZ:visited {
  color: #4c6072;
}

.red__2G2wn {
  color: #df5475;
  font-weight: bold;
}

