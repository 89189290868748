.schedule-list-item__1YpkG {
  position: relative;
  margin-top: min(8.5333333333vw,32px);
}

.schedule-list-item__1YpkG:first-child {
  margin-top: min(5.3333333333vw,20px);
}

.main__30UjA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #edeeef;
  padding-bottom: min(8.5333333333vw,32px);
}

.header__21M_8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2px;
}

@media only screen and (max-width: 449px) {
  .header__21M_8 {
    margin-right: min(3.2vw,12px);
  }
}

.label__3aGLe {
  margin: 16px 0;
}

.label__3aGLe .label-item__93iB4 {
  display: inline-block;
}

.label__3aGLe .label-item__93iB4:not(:last-child) {
  margin-right: 8px;
}

.title__1KCy8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: min(4.2666666667vw,16px);
}

.location-capacity__J27BX {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2px;
}

@media only screen and (max-width: 1199px) {
  .location-capacity__J27BX {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 2px;
  }
}

.action__3SI8E {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.mask__3edl6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(255,255,255,.8);
  color: #4c6072;
  text-align: center;
  font-weight: bold;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .mask__3edl6 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .mask__3edl6 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .mask__3edl6 {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.schedule-badge__2y9zY {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .schedule-badge__2y9zY {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .schedule-badge__2y9zY {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .schedule-badge__2y9zY {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .schedule-badge__2y9zY {
    padding: 4px 12px;
  }
}

@media only screen and (max-width: 449px) {
  .schedule-badge__2y9zY {
    padding: calc(1.0666666667vw) calc(1.0666666667vw);
  }
}

.schedule-badge__2y9zY.pink__3qusq {
  border: 1px solid #d6a0a0;
  color: #d6a0a0;
}

@media only screen and (min-width: 450px) {
  .badge-wrapper__XPAIJ {
    margin-left: 12px;
    min-width: 50px;
  }
}

@media only screen and (max-width: 449px) {
  .badge-wrapper__XPAIJ {
    margin-left: calc(1.0666666667vw);
    min-width: calc(13.3333333333vw);
  }
}

.service-label__DK0TN {
  display: inline-block;
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  margin-right: 7px;
  margin-bottom: 7px;
  color: #fff;
}

.likes-label__3G-N3 {
  background-color: #d6a0a0;
}

.multicreator-label__10MXa {
  background-color: #2d81b1;
}

.beauty-label__3PEjN {
  background-color: #204a88;
}

.money-label__2zSO1 {
  background-color: #ef8f59;
}

.zoom-link__19jmq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.zoom-link__19jmq .join-link__2RX8B {
  color: #4c6072;
  text-decoration: underline;
}

.zoom-link__19jmq .expired-link__7gFHZ {
  color: #df5475;
}

