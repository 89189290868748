@media only screen and (min-width: 769px) {
  .card-summary__1zMyU {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media only screen and (max-width: 769px) {
  .card-summary__1zMyU {
    display: block;
    margin-top: calc(7.8125vw);
  }
}

.first-group__3owj1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.label__2OgZg {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 30px;
}

@media only screen and (max-width: 769px) {
  .label__2OgZg {
    font-size: 13px;
    line-height: 1;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 769px) {
  .label__2OgZg {
    margin-right: calc(9.375vw);
  }
}

.card-info__3J9ad {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 769px) {
  .card-info__3J9ad {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.card-info__3J9ad .brand-icon__2xft- {
  margin-right: 24px;
}

@media only screen and (max-width: 769px) {
  .card-info__3J9ad .brand-icon__2xft- {
    margin: 0;
  }
}

.card-info__3J9ad .brand-icon__2xft- img {
  width: 33px;
}

@media only screen and (max-width: 769px) {
  .card-info__3J9ad .brand-icon__2xft- img {
    width: calc(10.3125vw);
  }
}

.card-info__3J9ad .exp__WmZND {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
}

@media only screen and (max-width: 769px) {
  .card-info__3J9ad .exp__WmZND {
    font-size: 13px;
    line-height: 1;
    letter-spacing: 2px;
  }
}

.card-info__3J9ad .last4__2EhC_ {
  margin-right: 24px;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 2px;
}

@media only screen and (max-width: 769px) {
  .card-info__3J9ad .last4__2EhC_ {
    font-size: 13px;
    line-height: 1;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 769px) {
  .card-info__3J9ad .last4__2EhC_ {
    margin: 0;
  }
}

@media only screen and (max-width: 769px) {
  .button-wrapper__1CRHz {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: calc(8.125vw);
  }
}

