.button-box__3KCmW {
  position: fixed;
  left: 0;
  bottom: 24px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 100;
}

@media only screen and (min-width: 450px) {
  .fixed-button__1urvu {
    width: 230px;
  }
}

.fixed-button__1urvu:not(:last-child) {
  margin-right: 12px;
}

@media only screen and (max-width: 449px) {
  .fixed-button__1urvu {
    width: calc(40vw);
  }
}

.fixed-button__prev__3d7YV {
  background-color: #fff;
}

