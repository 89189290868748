.loader-row__2tBie {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (min-width: 450px) {
  .loader-row__2tBie {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 449px) {
  .loader-row__2tBie {
    padding: calc(5.3333333333vw) 0;
  }
}

.loader__14Z6b path {
  fill: #e8ecef;
}

