@media only screen and (min-width: 1200px) {
  .page__tciN8 {
    padding: 0;
    margin-left: 128px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 593px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .page__tciN8 {
    padding: 0 32px;
  }
}

@media only screen and (max-width: 449px) {
  .page__tciN8 {
    padding: 0 calc(6.4vw);
  }
}

.page-header__3PrmV {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
  color: #1f2d32;
}

@media only screen and (min-width: 1200px) {
  .page-header__3PrmV {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .page-header__3PrmV {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .page-header__3PrmV {
    font-size: calc(4.2666666667vw);
    line-height: calc(6.4vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .page-header__3PrmV {
    border-bottom: 1px solid #dadbdd;
    padding-bottom: 32px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .page-header__3PrmV {
    padding: calc(4.2666666667vw) 0 calc(4.2666666667vw);
    border-bottom: 1px solid #dadbdd;
    margin-bottom: calc(6.4vw);
  }
}

