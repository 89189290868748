.check-box__KlWPk {
  background: #fff;
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.check-box__KlWPk.white__1BQqP {
  border: 2px solid #d3d4d8;
}

.check-box__KlWPk.primary__3cqX4 {
  border: 2px solid #4c6072;
  border-radius: 1px;
}

.check-box__KlWPk.primary__3cqX4.checked__16bxm {
  background: #4c6072;
}

.checked-icon__1WcOu {
  width: 16px;
  height: auto;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.primary__3cqX4 .checked-icon__1WcOu path {
  fill: #fff;
}

.white__1BQqP .checked-icon__1WcOu path {
  fill: #d6a0a0;
}

.check-box-card__2irsA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  font-family: europa,游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: normal;
}

@media only screen and (min-width: 1200px) {
  .check-box-card__2irsA {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .check-box-card__2irsA {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card__2irsA {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.check-box-card__2irsA:last-child {
  margin-right: 0px;
}

.check-box-card__2irsA.disabled__1RClS {
  cursor: default;
  opacity: .5;
}

@media only screen and (min-width: 450px) {
  .check-box-card__2irsA {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card__2irsA {
    font-size: 12px;
    margin-right: calc(5.3333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .check-box-card__2irsA .chk__2eVkS {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .check-box-card__2irsA .chk__2eVkS {
    margin-right: calc(2.1333333333vw);
  }
}

