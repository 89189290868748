.warning__25Gt- {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

.warning-body__3hAxn {
  background: #f9f0f1;
  color: #df5475;
}

.warning-body__3hAxn a {
  color: #df5475;
}

@media only screen and (min-width: 1200px) {
  .warning-body__3hAxn {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .warning-body__3hAxn {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .warning-body__3hAxn {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .warning-body__3hAxn {
    font-size: 10px;
    padding: 16px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 449px) {
  .warning-body__3hAxn {
    font-size: calc(2.6666666667vw);
    padding: calc(4.2666666667vw) calc(4.2666666667vw);
    line-height: 1.4;
  }
}

