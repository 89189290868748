.content__cklJ- {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.string-input__3-gyk {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-height: 100px;
}

