@media only screen and (min-width: 450px) {
  .pane__2aeuA {
    width: 400px;
    margin: 64px auto 112px;
  }
}

@media only screen and (max-width: 449px) {
  .pane__2aeuA {
    margin-top: calc(29.8666666667vw);
    padding: 0 calc(8.5333333333vw);
  }
}

.header__2c5Mi {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  text-align: center;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .header__2c5Mi {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .header__2c5Mi {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .header__2c5Mi {
    font-size: calc(5.3333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .body__1SN2D {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .body__1SN2D {
    margin-top: calc(8.5333333333vw);
  }
}

.splitter__2yc1w {
  border: 1px solid #f3f5f5;
}

@media only screen and (min-width: 450px) {
  .splitter__2yc1w {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 449px) {
  .splitter__2yc1w {
    margin-top: calc(10.6666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .form__1aTqK {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 449px) {
  .form__1aTqK {
    margin-top: calc(10.6666666667vw);
  }
}

.button__s-UvR {
  width: 100%;
}

@media only screen and (min-width: 450px) {
  .button-wrapper__1zFwn {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__1zFwn {
    margin-top: calc(8.5333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .new-user-cta__1tjH_ {
    margin-top: 58px;
  }
}

@media only screen and (max-width: 449px) {
  .new-user-cta__1tjH_ {
    margin-top: calc(15.4666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .link__3o5Rw {
    margin-top: 32px;
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 449px) {
  .link__3o5Rw {
    margin-top: calc(8.5333333333vw);
    margin-bottom: calc(12.8vw);
  }
}

