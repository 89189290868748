.h2__3xi3j {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 2px;
  color: #000;
  margin-top: 28px;
  margin-bottom: 15px;
}

.form-submit-button__39r5b {
  margin: 48px 0px;
}

.button__1TLOK {
  width: 204px;
}

@media only screen and (max-width: 449px) {
  .button__1TLOK {
    margin-top: calc(17.0666666667vw);
    width: 100%;
  }
}

.button__1TLOK:hover {
  opacity: .7;
}

.button__1TLOK.disabled__387dz {
  cursor: default;
  opacity: .5;
}

