.nav__1wi7u {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 56px;
}

.logo-wrapper__3qMyA {
  padding-top: 12px;
  padding-left: 32px;
}

.logo__1YXHk {
  width: 80px;
}

