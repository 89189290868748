.section-body__2Z8pi {
  margin-top: 32px;
}

.payment-info__-OQ6J {
  position: relative;
}

@media only screen and (min-width: 450px) {
  .payment-info__-OQ6J {
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 449px) {
  .payment-info__-OQ6J {
    margin-bottom: calc(12.8vw);
  }
}

.payment-info__-OQ6J .card-number__2ORrZ {
  margin-bottom: 48px;
  position: relative;
  padding-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
}

.payment-info__-OQ6J .card-number__2ORrZ.placeholder__1Odir {
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .payment-info__-OQ6J .card-number__2ORrZ.placeholder__1Odir {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .payment-info__-OQ6J .card-number__2ORrZ.placeholder__1Odir {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .payment-info__-OQ6J .card-number__2ORrZ.placeholder__1Odir {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.payment-info__-OQ6J .card-number__2ORrZ .brand-icon__13dcl {
  width: 35px;
}

.payment-info__-OQ6J .card-number__2ORrZ .card-number-text__1BylH {
  font-size: 12px;
  position: absolute;
  left: 50px;
  top: 18px;
}

.payment-info__-OQ6J .button__2y4DD {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 30px;
  width: 197px;
  color: #4c6072;
  border: 1px solid #4c6072;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 12px rgba(0,0,0,.03);
          box-shadow: 0px 1px 12px rgba(0,0,0,.03);
  border-radius: 112px;
  text-align: center;
  cursor: pointer;
}

.payment-info__-OQ6J .button__2y4DD:hover {
  opacity: .7;
}

.payment-history__QNX8o {
  margin-bottom: 22px;
  position: relative;
  padding-bottom: 22px;
  position: relative;
  border-bottom: 1px dashed #dadbdd;
}

@media only screen and (min-width: 450px) {
  .payment-history__QNX8o {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.payment-history__QNX8o .col__7-Wzj {
  vertical-align: top;
}

@media only screen and (min-width: 450px) {
  .payment-history__QNX8o .col__7-Wzj {
    width: 33.33%;
    float: left;
  }

  .payment-history__QNX8o .col__7-Wzj.action__2Ru8t {
    padding-left: 30px;
  }

  .payment-history__QNX8o .col__7-Wzj:last-child {
    width: 66.66%;
  }
}

@media only screen and (max-width: 449px) {
  .payment-history__QNX8o .col__7-Wzj {
    width: 100%;
    display: block;
  }

  .payment-history__QNX8o .col__7-Wzj.action__2Ru8t {
    padding-left: calc(0vw);
    margin-top: calc(4.5333333333vw);
  }
}

.payment-history__QNX8o .h3__Oi5sv {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 14px;
  color: #000;
  margin-bottom: 16px;
  margin-top: 10px;
}

.payment-history__QNX8o tr th {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 12px;
  line-height: 28px;
  color: #999;
  width: 108px;
  text-align: left;
}

.payment-history__QNX8o tr td {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 12px;
  line-height: 28px;
  position: relative;
}

.payment-history__QNX8o tr td.payment-method__1jLKZ, .payment-history__QNX8o tr td.payment-status__3cvWq {
  font-size: 10px;
  line-height: 28px;
}

.payment-history__QNX8o tr td.payment-status__3cvWq {
  background-color: #f3f5f5;
  height: 19px;
  line-height: 21px;
  display: inline-block;
}

@media only screen and (min-width: 450px) {
  .action-button__1OkDr {
    min-width: 190px;
    max-width: 220px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 449px) {
  .action-button__1OkDr {
    min-width: calc(50.6666666667vw);
    max-width: calc(58.6666666667vw);
    margin-top: calc(0vw);
  }
}

.change-button__3NCCY {
  position: absolute;
  top: 0;
}

@media only screen and (min-width: 450px) {
  .change-button__3NCCY {
    width: 107px;
    left: 250px;
  }
}

@media only screen and (max-width: 449px) {
  .change-button__3NCCY {
    width: calc(28.5333333333vw);
    right: calc(0vw);
  }
}

.brand-icon__13dcl {
  width: 35px;
  margin: 0px 3px;
}

.form-input-group__2X0JM {
  margin: 32px 0px;
}

.form-input-line__tK1xd {
  margin: 16px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 450px) {
  .card-number__2ORrZ, .installments__2nnOu {
    width: 327px;
  }
}

@media only screen and (max-width: 449px) {
  .card-number__2ORrZ, .installments__2nnOu {
    width: 100%;
  }
}

@media only screen and (min-width: 450px) {
  .month__Ht8ap, .year__1X6Z4, .cvc__27T4Z {
    width: 160px;
  }
}

@media only screen and (max-width: 449px) {
  .month__Ht8ap, .year__1X6Z4, .cvc__27T4Z {
    width: 48%;
  }
}

@media only screen and (min-width: 450px) {
  .month__Ht8ap {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 449px) {
  .month__Ht8ap {
    margin-right: 4%;
  }
}

.form-submit-button__3sKXO {
  margin: 48px 0px;
}

.error__3DPZ4 {
  background-color: #f9f0f1;
  color: #df5475;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .error__3DPZ4 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .error__3DPZ4 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .error__3DPZ4 {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .error__3DPZ4 {
    width: 430px;
    padding: 12px 14px 12px 18px;
    margin: 16px 0;
  }
}

@media only screen and (max-width: 449px) {
  .error__3DPZ4 {
    padding: calc(3.2vw) calc(3.7333333333vw) calc(3.2vw) calc(4.8vw);
    margin: calc(4.2666666667vw) 0;
  }
}

.card-summary__LV-Ua {
  margin-top: 0 !important;
  padding-top: 10px;
}

