.modal__y5_Cb {
  width: 100%;
}

.modal__y5_Cb.popup-notification-small__Ypsxf {
  max-width: 328px;
}

@media only screen and (min-width: 450px) {
  .modal__y5_Cb.default__10KrD {
    max-width: 960px;
  }

  .modal__y5_Cb.medium__1w9OJ {
    max-width: 750px;
  }

  .modal__y5_Cb.small__2Cudd {
    max-width: 548px;
  }

  .modal__y5_Cb.popup-notification-large__1lszU {
    max-width: 600px;
  }
}

@media screen and (max-width: 768px) {
  .modal__y5_Cb.small__2Cudd {
    padding: 56px;
  }
}

@media only screen and (max-width: 449px) {
  .modal__y5_Cb.small__2Cudd {
    padding: 32px;
  }
}

.button-wrapper__1VFWA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
  z-index: 1500;
}

.close-button__6MYZH {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
}

@media only screen and (min-width: 1200px) {
  .close-button__6MYZH {
    position: fixed;
    right: 40px;
    top: 40px;
    width: 56px;
    height: 56px;
    background-color: #fff;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .close-button__6MYZH {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 449px) {
  .close-button__6MYZH {
    width: calc(10.6666666667vw);
    height: calc(10.6666666667vw);
    border: 1px solid #fff;
    margin-bottom: calc(4vw);
  }
}

.close-button__6MYZH.exited__jlCdI, .close-button__6MYZH.exiting__1BGkR {
  opacity: 0;
}

.close-button__6MYZH.entered__3cFhr, .close-button__6MYZH.entering__NSZBR {
  opacity: 1;
}

.close-button-inner__2rJzR {
  position: relative;
  width: 100%;
  height: 100%;
}

.close-button-inner__2rJzR .bar__2k8Yh {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media only screen and (min-width: 1200px) {
  .close-button-inner__2rJzR .bar__2k8Yh {
    height: 2px;
    width: 25px;
    border-radius: 2px;
    background-color: #1f2c32;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .close-button-inner__2rJzR .bar__2k8Yh {
    height: 2px;
    width: 25px;
    border-radius: 2px;
    background-color: #fff;
  }
}

@media only screen and (max-width: 449px) {
  .close-button-inner__2rJzR .bar__2k8Yh {
    height: calc(0.5333333333vw);
    width: calc(6.6666666667vw);
    border-radius: calc(0.5333333333vw);
    background-color: #fff;
  }
}

.close-button-inner__2rJzR.entering__NSZBR .bar__2k8Yh, .close-button-inner__2rJzR.entered__3cFhr .bar__2k8Yh {
  -webkit-transition: opacity .2s ease-out .2s,-webkit-transform .2s ease-out .2s;
  transition: opacity .2s ease-out .2s,-webkit-transform .2s ease-out .2s;
  transition: opacity .2s ease-out .2s,transform .2s ease-out .2s;
  transition: opacity .2s ease-out .2s,transform .2s ease-out .2s,-webkit-transform .2s ease-out .2s;
}

.close-button-inner__2rJzR.entering__NSZBR .leftright__BehVP, .close-button-inner__2rJzR.entered__3cFhr .leftright__BehVP {
  opacity: 1;
}

@media only screen and (min-width: 450px) {
  .close-button-inner__2rJzR.entering__NSZBR .leftright__BehVP, .close-button-inner__2rJzR.entered__3cFhr .leftright__BehVP {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

@media only screen and (max-width: 449px) {
  .close-button-inner__2rJzR.entering__NSZBR .leftright__BehVP, .close-button-inner__2rJzR.entered__3cFhr .leftright__BehVP {
    -webkit-transform: rotate(-45deg) translateY(calc(0.2666666667vw));
            transform: rotate(-45deg) translateY(calc(0.2666666667vw));
  }
}

.close-button-inner__2rJzR.entering__NSZBR .rightleft__58m_9, .close-button-inner__2rJzR.entered__3cFhr .rightleft__58m_9 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.close-button-inner__2rJzR.exited__jlCdI .bar__2k8Yh, .close-button-inner__2rJzR.exiting__1BGkR .bar__2k8Yh {
  -webkit-transition: opacity .2s ease-out 0s,-webkit-transform .2s ease-out 0s;
  transition: opacity .2s ease-out 0s,-webkit-transform .2s ease-out 0s;
  transition: opacity .2s ease-out 0s,transform .2s ease-out 0s;
  transition: opacity .2s ease-out 0s,transform .2s ease-out 0s,-webkit-transform .2s ease-out 0s;
}

.close-button-inner__2rJzR.exited__jlCdI .leftright__BehVP, .close-button-inner__2rJzR.exiting__1BGkR .leftright__BehVP {
  opacity: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.close-button-inner__2rJzR.exited__jlCdI .rightleft__58m_9, .close-button-inner__2rJzR.exiting__1BGkR .rightleft__58m_9 {
  opacity: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.modal-body__henFI {
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  margin: auto;
  overflow: hidden;
}

.modal-body__henFI.entering__NSZBR, .modal-body__henFI.entered__3cFhr {
  top: 0;
  opacity: 1;
  -webkit-transition: top .2s ease-out .1s,opacity .2s ease-out .1s;
  transition: top .2s ease-out .1s,opacity .2s ease-out .1s;
}

.modal-body__henFI.exited__jlCdI, .modal-body__henFI.exiting__1BGkR {
  top: 20px;
  opacity: 0;
  -webkit-transition: top .2s ease-out 0s,opacity .2s ease-out 0s;
  transition: top .2s ease-out 0s,opacity .2s ease-out 0s;
}

@media only screen and (max-width: 449px) {
  .modal-body__henFI:not(.vertically-centered__YnA27) {
    margin-bottom: calc(19.2vw);
  }
}

@media only screen and (min-width: 450px) {
  .modal-body__henFI:not(.vertically-centered__YnA27) {
    margin-bottom: 72px;
  }
}

