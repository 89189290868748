.radio__3MRHX {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media only screen and (min-width: 450px) {
  .radio__3MRHX {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .radio__3MRHX {
    width: calc(4.2666666667vw);
    height: calc(4.2666666667vw);
  }
}

.checked-icon__UIRLC {
  color: #d6a0a0;
  width: 16px;
  height: auto;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.radio-card__3_-fI {
  background: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  border: 2px solid #dadbdd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-family: europa,游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
}

.radio-card__3_-fI.checked__3q_Vo {
  background: #f3f5f5;
}

@media only screen and (min-width: 450px) {
  .radio-card__3_-fI {
    margin-right: 16px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .radio-card__3_-fI {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .radio-card__3_-fI {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .radio-card__3_-fI {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .radio-card__3_-fI {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .radio-card__3_-fI {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .radio-card__3_-fI {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .radio-card__3_-fI {
    min-width: 160px;
    padding: 20px;
  }

  .radio-card__3_-fI.l__3Da0k {
    width: 100%;
  }
}

@media only screen and (max-width: 449px) {
  .radio-card__3_-fI {
    padding: calc(4.2666666667vw) calc(3.2vw) calc(4.2666666667vw) calc(5.3333333333vw);
  }

  .radio-card__3_-fI.s__2MeiG {
    width: calc(41.0666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .radio-card__3_-fI .radio__3MRHX {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .radio-card__3_-fI .radio__3MRHX {
    margin-right: calc(5.3333333333vw);
  }
}

.radio-card__3_-fI .label__1bMy6 {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.radio-card__3_-fI .radio-recommend-label__2cgfL {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 4px 12px;
  background-color: #df5475;
  color: #fff;
}

.radio-card__3_-fI .radio-recommend-label-small__29XAA {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

@media only screen and (min-width: 1200px) {
  .radio-card__3_-fI .radio-recommend-label-small__29XAA {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .radio-card__3_-fI .radio-recommend-label-small__29XAA {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .radio-card__3_-fI .radio-recommend-label-small__29XAA {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px) {
  .radio-card__3_-fI .radio-recommend-label-small__29XAA {
    max-width: calc(40vw);
  }
}

.radio-card__3_-fI .description__36SBc {
  color: #1f2c32;
  font-weight: normal;
  margin-top: 11px;
}

@media only screen and (min-width: 1200px) {
  .radio-card__3_-fI .description__36SBc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .radio-card__3_-fI .description__36SBc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .radio-card__3_-fI .description__36SBc {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px) {
  .radio-card__3_-fI .description__36SBc {
    margin-top: calc(3.7333333333vw);
  }
}

.radio-card-fa__2dAzA {
  background: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  border: 2px solid #dadbdd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-family: europa,游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-weight: bold;
}

.radio-card-fa__2dAzA.checked__3q_Vo {
  background: #f3f5f5;
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .radio-card-fa__2dAzA {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .radio-card-fa__2dAzA {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .radio-card-fa__2dAzA {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .radio-card-fa__2dAzA {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .radio-card-fa__2dAzA {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .radio-card-fa__2dAzA {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .radio-card-fa__2dAzA {
    min-width: 160px;
    padding: 20px;
  }
}

@media only screen and (max-width: 449px) {
  .radio-card-fa__2dAzA {
    padding: calc(4.2666666667vw) calc(3.2vw) calc(4.2666666667vw) calc(5.3333333333vw);
  }

  .radio-card-fa__2dAzA.s__2MeiG {
    width: calc(41.0666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .radio-card-fa__2dAzA .radio__3MRHX {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .radio-card-fa__2dAzA .radio__3MRHX {
    margin-right: calc(5.3333333333vw);
  }
}

.fa-top__2pwzD {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fa-input__2Yntf {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

@media only screen and (max-width: 449px) {
  .fa-input__2Yntf {
    min-height: calc(21.3333333333vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .fa-input__2Yntf {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .fa-input__2Yntf {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .fa-input__2Yntf {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .fa-input__2Yntf::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__2Yntf::-ms-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__2Yntf::placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .fa-input__2Yntf::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__2Yntf::-ms-input-placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
  .fa-input__2Yntf::placeholder {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .fa-input__2Yntf::-webkit-input-placeholder {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .fa-input__2Yntf::-ms-input-placeholder {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .fa-input__2Yntf::placeholder {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.fa-bottom__1hAND {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 450px) {
  .fa-bottom__1hAND {
    margin-left: 36px;
    margin-top: 8px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 449px) {
  .fa-bottom__1hAND {
    margin-top: calc(2.1333333333vw);
    margin-right: calc(3.2vw);
  }
}

