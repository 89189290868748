.user-info__XTlYE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu-icon__2fMs2 {
  color: #1f2c32;
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .menu-icon__2fMs2 {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 449px) {
  .menu-icon__2fMs2 {
    margin-left: calc(1.3333333333vw);
  }
}

.profile__2Cra1 {
  border-radius: 50%;
  background-color: #f3f5f5;
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .profile__2Cra1 {
    width: 40px;
    height: auto;
  }
}

@media only screen and (max-width: 449px) {
  .profile__2Cra1 {
    width: calc(10.6666666667vw);
    height: auto;
  }
}

