@media only screen and (min-width: 450px) {
  .payment-history-inner__379P2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.payment-history__U7ja4 {
  padding: 22px 0;
  border-bottom: 1px dashed #edeeef;
}

.payment-history__U7ja4 .col__18UhB {
  vertical-align: top;
}

@media only screen and (min-width: 450px) {
  .payment-history__U7ja4 .col__18UhB.title__2RN49 {
    width: 30%;
    margin-right: 16px;
  }

  .payment-history__U7ja4 .col__18UhB.detail__276Pm {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}

@media only screen and (max-width: 449px) {
  .payment-history__U7ja4 .col__18UhB {
    width: 100%;
    display: block;
  }

  .payment-history__U7ja4 .col__18UhB.action__36p2x {
    padding-left: calc(0vw);
    margin-top: calc(4.5333333333vw);
  }

  .payment-history__U7ja4 .col__18UhB.title__2RN49 {
    margin-bottom: min(4.2666666667vw,16px);
  }
}

.payment-history__U7ja4 tr {
  margin-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment-history__U7ja4 tr th {
  width: 80px;
  text-align: left;
}

@media only screen and (max-width: 1199px) {
  .payment-history__U7ja4 tr th {
    width: min(26.6666666667vw,100px);
  }
}

.payment-history__U7ja4 tr td {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.alert__2pndR {
  margin-bottom: 24px;
}

