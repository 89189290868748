.content__itpGS {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.string-input__MwkeM {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

