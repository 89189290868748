.text__PqNvp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 449px) {
  .text__PqNvp {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.label__3eLdr {
  background-color: #fff;
  color: #6b9aaa;
}

@media only screen and (min-width: 450px) {
  .label__3eLdr {
    padding: 5px 6px;
    margin-right: 35px;
  }
}

@media only screen and (max-width: 449px) {
  .label__3eLdr {
    padding: calc(1.3333333333vw) calc(1.6vw);
    margin-right: calc(6.4vw);
  }
}

@media only screen and (max-width: 449px) {
  .button__2BMV_ {
    margin-top: calc(5.3333333333vw);
    width: 100%;
  }
}

@media only screen and (min-width: 450px)and (min-width: 1200px) {
  .box-wrapper__2v8YY {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (min-width: 450px)and (max-width: 1199px) {
  .box-wrapper__2v8YY {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 449px) {
  .box-wrapper__2v8YY {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .box-wrapper__2v8YY {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .box-wrapper__2v8YY {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .box-wrapper__2v8YY {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .box__-iBNU {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.foot-note__2EmfB {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #df5475;
  text-align: right;
}

@media only screen and (min-width: 450px) {
  .foot-note__2EmfB {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 449px) {
  .foot-note__2EmfB {
    margin-top: calc(1.0666666667vw);
  }
}

