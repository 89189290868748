.menu__1YNxU {
  color: #1f2c32;
}

@media only screen and (min-width: 450px) {
  .menu__1YNxU {
    width: 266px;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 449px) {
  .menu__1YNxU {
    width: 100%;
  }
}

.header-wrapper__338cT {
  padding: 0 24px;
  margin-bottom: 24px;
}

.title__18foM {
  font-family: europa,sans-serif;
  font-weight: normal;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .title__18foM {
    font-size: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .title__18foM {
    font-size: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .title__18foM {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .title__18foM {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .title__18foM {
    font-size: calc(8.5333333333vw);
    line-height: calc(10.6666666667vw);
  }
}

.sub-title__1h1Xc {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #afb0b5;
}

@media only screen and (min-width: 1200px) {
  .sub-title__1h1Xc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .sub-title__1h1Xc {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-title__1h1Xc {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .sub-title__1h1Xc {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 449px) {
  .sub-title__1h1Xc {
    margin-top: calc(1.0666666667vw);
  }
}

.menu-item__1SDj1 {
  cursor: pointer;
  text-decoration: none;
  display: block;
  z-index: 5002;
}

@media only screen and (min-width: 450px) {
  .menu-item__1SDj1 {
    padding: 24px;
  }

  .menu-item__1SDj1:hover {
    background-color: #f3f5f5;
  }
}

@media only screen and (max-width: 449px) {
  .menu-item__1SDj1 {
    padding: calc(4.2666666667vw) calc(6.4vw);
  }
}

.menu-item__1SDj1 .menu-item-text__2FQsX {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  text-decoration: none;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .menu-item__1SDj1 .menu-item-text__2FQsX {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .menu-item__1SDj1 .menu-item-text__2FQsX {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .menu-item__1SDj1 .menu-item-text__2FQsX {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px)and (min-width: 1200px) {
  .menu-item__1SDj1 .menu-item-text__2FQsX {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (min-width: 450px)and (max-width: 1199px) {
  .menu-item__1SDj1 .menu-item-text__2FQsX {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px)and (max-width: 449px) {
  .menu-item__1SDj1 .menu-item-text__2FQsX {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.menu-item__1SDj1.active__IvNK1 {
  font-weight: bold;
}

.service-items-header-wrapper__e2cqr {
  padding: 0 24px;
  margin-top: 40px;
  margin-bottom: 24px;
}

.service-items-title__1UmLo {
  font-family: europa,sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .service-items-title__1UmLo {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .service-items-title__1UmLo {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .service-items-title__1UmLo {
    font-size: calc(5.3333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

.service-items-sub-title__2DgJD {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .service-items-sub-title__2DgJD {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .service-items-sub-title__2DgJD {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .service-items-sub-title__2DgJD {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .service-items-sub-title__2DgJD {
    margin-top: 8px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .service-items-sub-title__2DgJD {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .service-items-sub-title__2DgJD {
    margin-top: calc(2.1333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .likes-logo-wrapper__2hUl4 {
    padding: 10px 24px 0;
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 449px) {
  .likes-logo-wrapper__2hUl4 {
    padding: calc(2.6666666667vw) calc(6.4vw) 0;
    margin-bottom: calc(7.4666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .likes-logo-svg__26hNN {
    width: 80px;
  }
}

@media only screen and (max-width: 449px) {
  .likes-logo-svg__26hNN {
    width: calc(21.3333333333vw);
  }
}

.beauty-logo-wrapper__39oQi {
  position: relative;
}

@media only screen and (min-width: 450px) {
  .beauty-logo-wrapper__39oQi {
    padding: 0 24px;
  }
}

@media only screen and (max-width: 449px) {
  .beauty-logo-wrapper__39oQi {
    padding: 0 calc(6.4vw);
  }
}

@media only screen and (min-width: 450px) {
  .beauty-logo-svg__3ChQd {
    width: 95px;
  }
}

@media only screen and (max-width: 449px) {
  .beauty-logo-svg__3ChQd {
    width: calc(25.3333333333vw);
  }
}

.money-logo-wrapper__20Kso {
  position: relative;
}

@media only screen and (min-width: 450px) {
  .money-logo-wrapper__20Kso {
    padding: 0 24px;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 449px) {
  .money-logo-wrapper__20Kso {
    padding: 0 calc(6.4vw);
    margin-top: calc(7.4666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .money-logo-svg__1S96k {
    width: 95px;
  }
}

@media only screen and (max-width: 449px) {
  .money-logo-svg__1S96k {
    width: calc(25.3333333333vw);
  }
}

.label-new__21ivD {
  font-family: europa,sans-serif;
  color: #1f2c32;
  background-color: #f3f5f5;
  padding: 4px 9px;
  margin-left: 15px;
  border-radius: 5px;
  position: absolute;
}

@media only screen and (min-width: 1200px) {
  .label-new__21ivD {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .label-new__21ivD {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label-new__21ivD {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px) {
  .label-new__21ivD {
    padding: calc(1.0666666667vw) calc(2.4vw);
    margin-left: calc(4vw);
    border-radius: calc(1.3333333333vw);
  }
}

