.page__1iidI {
  padding: 32px 32px 96px 32px;
}

@media screen and (max-width: 544px) {
  .page__1iidI {
    padding: 24px 24px 64px 24px;
  }
}

.page-inner__1hItp {
  max-width: 1024px;
  margin: 0 auto;
}

