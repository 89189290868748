.overlay__3-G07 {
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.overlay__3-G07.centered__2cjyV {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.overlay__3-G07:not(.centered__2cjyV) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.overlay__3-G07.entering__Ayvek, .overlay__3-G07.entered___Pi4k {
  background: rgba(205,208,216,.8);
  visibility: visible;
}

.overlay__3-G07.exiting__13R8H, .overlay__3-G07.exited__yHneq {
  background-color: rgba(0,0,0,0);
}

.overlay__3-G07.exited__yHneq {
  visibility: hidden;
}

.full-overlay__3B5uC {
  width: 100%;
}

.button-wrapper__1MBrD {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
}

@media only screen and (min-width: 1200px) {
  .button-wrapper__1MBrD {
    padding: 32px 32px 40px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .button-wrapper__1MBrD {
    padding: 20px 20px 40px;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__1MBrD {
    padding: calc(5.3333333333vw) calc(5.3333333333vw) calc(10.6666666667vw);
  }
}

.full-overlay-body__-exTL {
  position: relative;
  background-color: #fff;
  margin: auto;
  overflow: auto;
  min-height: 100vh;
  width: 100%;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;
}

.full-overlay-body__-exTL.entering__Ayvek, .full-overlay-body__-exTL.entered___Pi4k {
  top: 0;
  opacity: 1;
  -webkit-transition: top .2s ease-out .1s,opacity .2s ease-out .1s;
  transition: top .2s ease-out .1s,opacity .2s ease-out .1s;
}

.full-overlay-body__-exTL.exited__yHneq, .full-overlay-body__-exTL.exiting__13R8H {
  top: 20px;
  opacity: 0;
  -webkit-transition: top .2s ease-out 0s,opacity .2s ease-out 0s;
  transition: top .2s ease-out 0s,opacity .2s ease-out 0s;
}

@media only screen and (max-width: 449px) {
  .full-overlay-body__-exTL {
    max-width: mob2(327);
  }
}

