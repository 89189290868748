.hamburger-button__LHaGp {
  position: relative;
  width: 20px;
  height: 20px;
}

.hamburger-button__LHaGp.sticky__vf6Ku {
  position: fixed;
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .hamburger-button__LHaGp.sticky__vf6Ku {
    top: 15px;
    right: 15px;
  }
}

@media only screen and (max-width: 449px) {
  .hamburger-button__LHaGp.sticky__vf6Ku {
    top: calc(4vw);
    right: calc(4vw);
  }
}

.hamburger-button__LHaGp span {
  position: absolute;
  display: inline-block;
  -webkit-transition: all .4s;
  transition: all .4s;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  border-radius: 2px;
}

.hamburger-button__LHaGp span:nth-of-type(1) {
  top: 0;
}

.hamburger-button__LHaGp span:nth-of-type(2) {
  top: 9px;
}

.hamburger-button__LHaGp span:nth-of-type(3) {
  bottom: 0;
}

.hamburger-button__LHaGp.open__2F_F2 span:nth-of-type(1) {
  -webkit-transform: translateY(10px) rotate(-315deg);
          transform: translateY(10px) rotate(-315deg);
}

.hamburger-button__LHaGp.open__2F_F2 span:nth-of-type(2) {
  opacity: 0;
}

.hamburger-button__LHaGp.open__2F_F2 span:nth-of-type(3) {
  bottom: 0;
  -webkit-transform: translateY(-8px) rotate(315deg);
          transform: translateY(-8px) rotate(315deg);
}

.hamburger-button__LHaGp.dark__2K6Ad span {
  background-color: #000;
  opacity: .5;
}

.hamburger-button__LHaGp.white__2PK8R span {
  background-color: #fff;
  opacity: 1;
}

.hamburger-button__LHaGp.white__2PK8R.open__2F_F2 span {
  background-color: #000;
  opacity: .5;
}

.hamburger-button__LHaGp.white__2PK8R.open__2F_F2 span:nth-of-type(2) {
  opacity: 0;
}

