@media only screen and (min-width: 450px) {
  .question-card__3098P:not(:first-child) {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .question-card__3098P:not(:first-child) {
    margin-top: calc(6.4vw);
  }
}

