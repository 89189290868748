.date-input-non-sp__XFP04 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.selector__2PANC {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.selector__2PANC.wide__1E3oR {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}

@media only screen and (min-width: 450px) {
  .selector__2PANC+.selector__2PANC {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 449px) {
  .selector__2PANC+.selector__2PANC {
    margin-left: calc(3.2vw);
  }
}

.date-input-sp__2lrDq {
  position: relative;
  display: inline-block;
}

.date-input-sp__2lrDq.small__GgOSI {
  width: 30%;
}

.select__2Pgtj {
  width: 100%;
  outline: none;
  text-indent: .01px;
  text-overflow: "";
  background: #f3f5f5;
  vertical-align: middle;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid rgba(0,0,0,0);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

.select__2Pgtj.error__2n5C6 {
  border: 1px solid #df5475;
}

.select__2Pgtj:focus {
  border: 1px solid #3c676e;
  background: #fff;
}

.select__2Pgtj:hover {
  border: 1px solid #3c676e;
}

.select__2Pgtj.selected__1xCwB {
  color: #1f2c32;
}

.select__2Pgtj:not(.selected__1xCwB) {
  color: #d3d4d8;
}

@media only screen and (min-width: 1200px) {
  .select__2Pgtj.m__2SVmp {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .select__2Pgtj.m__2SVmp {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .select__2Pgtj.m__2SVmp {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .select__2Pgtj.m__2SVmp {
    height: 48px;
    border-radius: 8px;
    padding: 0 16px 0 16px;
  }
}

@media only screen and (max-width: 449px) {
  .select__2Pgtj.m__2SVmp {
    height: calc(12.8vw);
    border-radius: calc(2.1333333333vw);
    padding: 0 calc(4.2666666667vw) 0 calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .select__2Pgtj.l__2Z8UO {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .select__2Pgtj.l__2Z8UO {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .select__2Pgtj.l__2Z8UO {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .select__2Pgtj.l__2Z8UO {
    height: 72px;
    border-radius: 8px;
    padding: 0 16px 0 16px;
  }
}

@media only screen and (max-width: 449px) {
  .select__2Pgtj.l__2Z8UO {
    height: calc(14.9333333333vw);
    border-radius: calc(2.1333333333vw);
    padding: 0 calc(4.2666666667vw) 0 calc(4.2666666667vw);
  }
}

