.primary__1F90v {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
}

.primary-body__2u_v0 {
  background: #eef6f8;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .primary-body__2u_v0 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .primary-body__2u_v0 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .primary-body__2u_v0 {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .primary-body__2u_v0 {
    font-size: 10px;
    padding: 12px 24px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 449px) {
  .primary-body__2u_v0 {
    font-size: calc(2.6666666667vw);
    padding: calc(5.3333333333vw) calc(6.4vw);
    line-height: 1.4;
  }
}

