.lower__m6gNZ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media only screen and (min-width: 450px) {
  .lower__m6gNZ {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .lower__m6gNZ {
    margin-top: calc(2.1333333333vw);
  }
}

.upper__ej5XC {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (min-width: 450px) {
  .upper__ej5XC {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .upper__ej5XC {
    margin-bottom: calc(2.1333333333vw);
  }
}

.label__3zYyv {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #1f2c32;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .label__3zYyv {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px)and (max-width: 1199px) {
  .label__3zYyv {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__3zYyv {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

