.user-info__3Hf_C {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.user-info-inner__A5Zee {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 44px;
  padding: 0 16px 0 7px;
}

.user-info-inner__A5Zee.active__2r_c1 {
  background: #fbfbfc;
  border-radius: 400px;
  -webkit-box-shadow: 2px 2px 12px rgba(0,0,0,.08);
          box-shadow: 2px 2px 12px rgba(0,0,0,.08);
}

.profile__2QJAF {
  background: #f3f5f5;
  width: 32.85px;
  height: 32.85px;
  border-radius: 50%;
  margin-right: 12px;
}

.name__1xS7Z {
  font-family: europa,sans-serif;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 1.4px;
}

