* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.form-group__-7TjO {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-group__-7TjO+.form-group__-7TjO {
  margin-top: 20px;
}

.form-label__3AlgN {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  color: #1f2c32;
  font-size: 12px;
  width: 88px;
  margin: auto 0px;
}

.id__2vICG, .name__YVcuo, .birthday__1y_JP, .furigana__GKeQr, .age__1jXsK, .job-company__34l8l, .job-department__23I4H, .job-title__3gPY_ {
  width: 100%;
}

.profile-image__3xZR- {
  height: 78px;
  width: 78px;
  border-radius: 50%;
  margin-right: 25px;
}

.profile-image__3xZR-.hidden__2MTYl {
  visibility: hidden;
}

.form-submit-button__1Lb2h {
  margin-bottom: 48px;
  margin-top: 64px;
}

@media only screen and (max-width: 449px) {
  .form-submit-button__1Lb2h {
    margin-top: 48px;
  }
}

.image-form-input-wrapper__Br-Xj {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.image-form-input__sXqMF {
  display: inline-block;
  border: 1px solid #4c6072;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 12px rgba(0,0,0,.03);
          box-shadow: 0px 1px 12px rgba(0,0,0,.03);
  border-radius: 112px;
  width: 140px;
  cursor: pointer;
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 10px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 1.6px;
}

.image-form-input__sXqMF .error__L7XT2 {
  color: #df5475;
  margin-top: 10px;
}

.image-form-input__sXqMF input {
  display: none;
}

@media only screen and (max-width: 449px) {
  .image-form-input__sXqMF {
    width: 112px;
  }
}

.button__iTYel {
  font-family: 游ゴシック体,"Yu Gothic",YuGothic,Helvetica,Arial,"Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,メイリオ,sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  width: 196px;
  background: #4c6072;
  color: #fff;
  border: 1px solid #4c6072;
  border-radius: 400px;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 449px) {
  .button__iTYel {
    width: 100%;
  }
}

.button__iTYel:hover {
  opacity: .7;
}

.button__iTYel.disabled__1FFIY {
  cursor: default;
  opacity: .5;
}

