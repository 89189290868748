@media only screen and (min-width: 450px) {
  .container__1QwsY {
    margin-top: -16px;
  }
}

@media only screen and (max-width: 449px) {
  .container__1QwsY {
    margin-top: calc(-4.2666666667vw);
  }
}

